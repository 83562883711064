/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    color: #333;
  }
  .trademark {
    font-size: 0.7em; /* Make it smaller than the normal text */
    vertical-align:super; /* Aligns it as superscript */
  }
  
  /* Product Container */
  .product-container {
    max-width: 1200px;
    margin: 90px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Product Title */
  .product-title {
    font-size: 3.1rem; /* Adjusted to match other text sizes */
    color: #004d40;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .spotimage {
    width: 100%;            /* Make the image take the full width of its container */
    height: auto;          /* Maintain the aspect ratio of the image */
    max-width: 400px;      /* Set a maximum width for larger screens, adjust as necessary */
    margin: 0 auto;       /* Center the image */
  }
  
  /* Responsive Design for Tablets */
  @media (max-width: 768px) {
    .spotimage {
      max-width: 90%;      /* Adjust the max width for tablets */
    }
  }
  
  /* Responsive Design for Mobile Phones */
  @media (max-width: 480px) {
    .spotimage {
      max-width: 100%;     /* Full width for mobile devices */
    }
  }
  
  /* Product Sub Title */
  .product-sub-title {
    font-size: 2.1rem;
    color: #00796b;
    margin: 20px 0;
    text-align: center;
  }
  
  /* Product Button */
  .product-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 2.1rem;
    color: #fff;
    background-color: #00796b;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;
    margin: 20px auto;
    display: block;
  }
  
  .product-button:hover {
    background-color: #004d40;
  }
  
  /* Product Features styling */
  .product-features {
    background-color: #e0f2f1;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
  }
  
  .product-features h2 {
    font-size: 2.1rem;
    color: #004d40;
    border-bottom: 2px solid #004d40;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .product-features ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  /* Product Specifications styling */
  .product-specifications {
    background-color: #e0f2f1;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
  }
  
  .product-specifications h2 {
    font-size: 2.1rem;
    color: #004d40;
    border-bottom: 2px solid #004d40;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .product-specifications ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  /* Training Info styling */
  .training-info {
    background-color: #f1f8e9;
    padding: 15px;
    border-radius: 8px;
    font-size: 1.7rem;
    line-height: 1.1;
    margin-top: 20px;
  }
  
  /* Footer styling */
  #footer {
    margin-top: 50px;
    padding: 20px;
    background-color: #f5f7f6;
    color: #fff;
    text-align: center;
  }
  
  .footer-line {
    border-top: 1px solid #fff;
    margin-bottom: 10px;
  }
  
  .container.text-center {
    text-align: center;
  }
  
  .container.text-center h4 {
    margin: 0;
  }
  
  .container.text-center p {
    margin: 0;
    color: #fff;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .product-title {
      font-size: 2rem;
    }
  
    .product-image {
      width: 70%;
    }
  
    .product-sub-title,
    .product-features h2,
    .product-specifications h2 {
      font-size: 1.5rem;
    }
  
    .product-button {
      font-size: 1rem;
    }
  }
  