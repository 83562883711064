/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: #333;
}

/* Main Container */
.product-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Title Styling */
.product-title {
  font-size: 2.5rem;
  color: #004d40;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
}

/* Subtitle Styling */
.product-sub-title {
  font-size: 1.8rem;
  color: #00796b;
  text-align: center;
  margin: 20px 0;
}

/* Image Styling */
.inotimage {
  width: 100%;            /* Make the image take the full width of its container */
  height: auto;          /* Maintain the aspect ratio of the image */
  max-width: 550px;      /* Set a maximum width for larger screens, adjust as necessary */
  margin: 0 auto;       /* Center the image */
}


/* Description Styling */
.product-description {
  font-size: 1rem;
  line-height: 1.5;
  margin: 20px 0;
}

/* Button Styling */
.product-button {
  display: block;
  padding: 12px 24px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #00796b;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  margin: 20px auto;
  transition: background-color 0.3s;
}

.product-button:hover {
  background-color: #004d40;
}

/* Video Container */
.product-video-container {
  text-align: center;
  margin: 40px 0;
}

.product-video-container iframe {
  width: 100%;
  height: 400px;
}

/* Feature and Scope Sections */
.section-container {
  background-color: #e0f2f1;
  padding: 20px;
  border-radius: 8px;
  margin: 30px 0;
}

.section-container h2 {
  font-size: 2.3rem;
  color: #004d40;
  border-bottom: 2px solid #004d40;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.section-container ul {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 1.9rem;
}

/* Training Information */
.training-info {
  background-color: #f1f8e9;
  padding: 20px;
  border-radius: 8px;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-top: 20px;
}

/* Footer Styling */
#footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #004d40;
  color: #fff;
  text-align: center;
}

.footer-line {
  border-top: 1px solid #fff;
  margin-bottom: 5px;
}

#footer p {
  margin: 0;
  color: black;
  font-weight: bold;
}

/* Responsive Design for Laptops */
@media (max-width: 1024px) {
  .product-container {
    padding: 15px;
  }

  .product-title {
    font-size: 2.2rem;
  }

  .product-sub-title {
    font-size: 1.6rem;
  }

  .product-description {
    font-size: 1.1rem;
  }

  .product-button {
    font-size: 1.1rem;
  }

  .product-video-container iframe {
    width: 100%;
    height: 350px;
  }
}

/* Responsive Design for Tablets */
@media (max-width: 768px) {
  .product-container {
    padding: 10px;
    width: 100%;         /* Ensure the container takes full width */
  }

  .inotimage {
    max-width: 90%;      /* Adjust the max width for tablets */
    height: auto;
  }

  .product-title {
    font-size: 2.5rem;
    text-align: center;  /* Center align title on smaller devices */
  }

  .product-sub-title {
    font-size: 1.6rem;
    text-align: center;  /* Center align subtitle */
  }

  .product-description {
    font-size: 1.2rem;   /* Increased font size */
    padding: 0 10px;     /* Add padding for readability */
    text-align: justify; /* Justify text for readability */
  }

  .product-button {
    font-size: 1.1rem;
    padding: 10px 20px;
    width: auto;
  }

  .product-video-container iframe {
    width: 100%;
    height: 300px;
  }

  .section-container h2 {
    font-size: 2rem;
    text-align: center;
  }

  .section-container ul {
    font-size: 1.4rem;
  }

  .training-info {
    font-size: 1.1rem;
  }
}

/* Responsive Design for Mobile Phones */
@media (max-width: 480px) {
  .product-container {
    width: 100%;         /* Full width for mobile */
    padding: 10px;
  }

  .product-title {
    font-size: 1.8rem;
    text-align: center; /* Center align title for mobile */
  }

  .inotimage {
    max-width: 100%;
    height: auto;      /* Full width on mobile */
  }

  .product-sub-title {
    font-size: 1.4rem;
    text-align: center; /* Center align subtitle for mobile */
    padding: 0 10px;   /* Add padding for readability */
  }

  .product-description {
    font-size:1.71rem;   /* Increase font size for readability */
    padding: 0 15px;   /* Add padding to avoid text touching edges */
    text-align: justify; /* Justify text for readability */
  }

  .product-button {
    font-size: 1rem;
    padding: 10px 20px;
    width: 90%; /* Keep the button width responsive */
  }

  .product-video-container iframe {
    width: 100%;
    height: 250px;
  }

  .section-container {
    padding: 10px;
  }

  .section-container h2 {
    font-size: 1.8rem;
    text-align: center;
  }

  .section-container ul {
    font-size: 1.5rem;
  }

  .training-info {
    font-size: 1rem;
  }
}
@media (max-width: 410px) {
  .product-container {
    width: 100%;         /* Full width for mobile */
    padding: 10px;
  }

  .product-title {
    font-size: 1.8rem;
    text-align: center; /* Center align title for mobile */
  }

  .inotimage {
    max-width: 100%;
    height: auto;      /* Full width on mobile */
  }

  .product-sub-title {
    font-size: 1.4rem;
    text-align: center; /* Center align subtitle for mobile */
    padding: 0 10px;   /* Add padding for readability */
  }

  .product-description {
    font-size:1.71rem;   /* Increase font size for readability */
    padding: 0 15px;   /* Add padding to avoid text touching edges */
    text-align: justify; /* Justify text for readability */
  }

  .product-button {
    font-size: 1rem;
    padding: 10px 20px;
    width: 90%; /* Keep the button width responsive */
  }

  .product-video-container iframe {
    width: 100%;
    height: 250px;
  }

  .section-container {
    padding: 10px;
  }

  .section-container h2 {
    font-size: 1.8rem;
    text-align: center;
  }

  .section-container ul {
    font-size: 1.5rem;
  }

  .training-info {
    font-size: 1rem;
  }
}
/* Additional Adjustments for Very Small Screens (375px or less) */
@media (max-width: 375px) {
  .product-container {
    width: 100%;
    padding: 10px;
  }

  .inotimage {
    max-width: 100%; /* Ensure images take up full width */
  }

  .product-title {
    font-size: 1.5rem;
  }

  .product-sub-title {
    font-size: 1.3rem;
  }

  .product-description {
    font-size: 1.7rem;
    padding: 0 15px;
    text-align: justify;
  }

  .product-button {
    font-size: 0.9rem;
    padding: 8px 16px;
    width: 90%; /* Ensure button doesn't span full width */
  }

  .section-container ul {
    font-size: 1.7rem;
  }
}