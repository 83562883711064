/* Global Styles */
* {
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  /* Product Container */
  .product-container {
    max-width: 1200px;
    margin: 90px auto; /* Center container */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px; /* Added padding */
  }
  
  .trademark {
    font-size: 0.7em; /* Make it smaller than the normal text */
    vertical-align: super; /* Aligns it as superscript */
  }
  
  /* Product Title */
  .product-title {
    font-size: 3.1rem;
    color: #004d40;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }
  

  .product-image {
    width: 570px;
    height: 300px; 
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    margin: 0 10px; /* Space between images */
  }
  
  /* Product Description */
  .product-description {
    font-size: 2.1rem;
    line-height: 1.5; /* Improved line height */
  }
  
  /* Product Sub Title */
  .product-sub-title {
    font-size: 2.1rem;
    color: #00796b;
    margin: 20px 0;
    text-align: center;
  }
  
  /* Product Button */
  .product-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 2.1rem;
    color: #fff;
    background-color: #00796b;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;
    margin: 20px auto;
  }
  
  .product-button:hover {
    background-color: #004d40;
  }
  
  /* Product Features styling */
  .product-features {
    background-color: #e0f2f1;
    padding: 20px;
    border-radius: 8px;
  }
  
  .product-features h2 {
    font-size: 2.2rem;
    color: #004d40;
    border-bottom: 2px solid #004d40;
    margin-bottom: 15px;
  }
  
  .product-features ul {
    list-style-type: disc;
    padding-left: 20px; /* Added padding for list */
  }
  
  /* Scope of Supply Includes styling */
  .scope-of-supply {
    background-color: #e0f2f1;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
  }
  
  .scope-of-supply h2 {
    font-size: 2.2rem;
    color: #004d40;
    border-bottom: 2px solid #004d40;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .scope-of-supply ul {
    list-style-type: disc;
    padding-left: 20px; /* Added padding for list */
  }
  
  /* Training Info styling */
  .training-info {
    background-color: #f1f8e9;
    padding: 15px;
    border-radius: 8px;
    font-size: 1.7rem;
    line-height: 1.6;
    margin-top: 20px;
  }
  
  /* Footer styling */
  #footer {
    margin-top: 50px;
    padding: 20px;
    background-color: #00796b; /* Change to match theme */
    color: #fff;
    text-align: center;
  }
  
  .footer-line {
    border-top: 1px solid #fff;
    margin-bottom: 10px;
  }
  
  .container.text-center {
    text-align: center;
  }
  
  .container.text-center h4 {
    margin: 0;
  }
  
  .container.text-center p {
    margin: 0;
    color: #fff;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .product-title {
      font-size: 2rem;
    }
  
    .product-image {
      width: 90%;
    }
  
    .product-sub-title,
    .product-features h2,
    .scope-of-supply h2 {
      font-size: 1.5rem;
    }
  
    .product-button {
      font-size: 1rem;
    }
  }
  