.portfolio-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create 4 equal columns */
  gap: 10px; /* Increase space between images */
  justify-items: center; /* Center the images within the grid */
}

.portfolio-item-wrapper {
  border-radius: 20px;
  position: static;
  overflow: hidden;
  width: 100%;
  border: 5px solid #ddd; /* Border for the frame effect */
  padding: 10px; /* Padding inside the frame */
  background-color: white; /* Background color for the frame */
  box-shadow: 0 4px 8px rgba(241, 239, 239, 0.1); /* Shadow for 3D effect */
  box-sizing: border-box; /* Include border and padding in width/height calculations */
  text-align: center; /* Center align the text */
  margin-bottom:10px; /* Add space at the bottom to separate rows */
}

.small-image {
  width: 270px; /* Fixed width for the image */
  height: 250px; /* Fixed height for the image */
  object-fit: contain; /* Maintain aspect ratio inside the fixed box */
  cursor: pointer;
  transition: transform 0.3s; /* Smooth transition effect */
  display: block; /* Ensures block-level display for spacing */
  margin: 0 auto; /* Center the image within the wrapper */
  padding: 7px;
}

.small-image-title {
  font-size: 1em; /* Smaller font size for the title */
  color: #333; /* Text color */
  margin-top: 10px; /* Space between image and title */
  border: 2px solid white; /* Black border around the title */
  padding: 5px; /* Padding inside the title box */
  border-radius: 5px; /* Rounded corners for the title box */
  background-color: white; /* Background color for the title box */
  width: 100%; /* Full width of the title box */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
  text-align: center; /* Center align the text */
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 248, 248, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1100;
}

.full-screen-title {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 2.0em;
  text-align: center;
  z-index: 1200;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background-color: rgba(250, 250, 250, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.large-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

/* Styles for Desktop (default) */
.portfolio-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns for desktop */
  gap: 10px; /* Space between items */
  justify-items: center;
}

/* Styles for larger tablets */
@media (max-width: 1024px) {
  .portfolio-items {
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns for larger tablets */
  }
}

/* Styles for smaller tablets */
@media (max-width: 768px) {
  .portfolio-items {
    grid-template-columns: repeat(2, 1fr); /* 2 equal columns for smaller tablets */
  }

  .small-image {
    width: 220px; /* Adjust smaller fixed width for tablets */
    height: 200px; /* Adjust smaller fixed height for tablets */
  }

  .small-image-title {
    font-size: 0.9em; /* Adjust smaller font for tablet titles */
  }
}

/* Styles for mobile devices */
@media (max-width: 480px) {
  .portfolio-items {
    grid-template-columns: 1fr; /* Single column for mobile */
  }

  .small-image {
    width: 150px; /* Smaller width for mobile images */
    height: 150px; /* Smaller height for mobile images */
    object-fit: contain; /* Keep aspect ratio */
  }

  .small-image-title {
    font-size: 0.85em; /* Smaller font size for mobile */
  }

  .portfolio-item-wrapper {
    padding: 5px; /* Less padding on mobile */
  }

  .full-screen-overlay {
    background: rgba(0, 0, 0, 0.8); /* Darker overlay on mobile */
  }

  .large-image {
    max-width: 100%; /* Make sure large image fits the screen on mobile */
    max-height: 100%;
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
  }

  .full-screen-title {
    font-size: 1.5em; /* Slightly smaller title font on mobile */
    top: 10px; /* Adjust positioning for smaller screens */
  }
}
