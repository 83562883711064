/* General styles for the slider */
.slider-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
}

.feature-box {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 0 auto;
}

.slider-text {
  font-size: 22px;
  color: black;
  line-height: 1.6;
  padding: 10px 15px;
  font-family: 'Arial, sans-serif';
  text-align: center;
}

/* Slider Section */
.slider-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Adjust slider container to fixed width and height */
.slider-container {
  width: 610px; /* Fixed width of the container */
  height: 590px; /* Fixed height of the container */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0px 4px 20px transparent;
}

/* Image within the container */
.slider-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.slider-title {
  font-size: 18px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
}

/* Slider arrows */
.slider-arrow {
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.slider-arrow:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

/* Responsive styles for devices */

/* Tablets (max-width: 1024px) */
@media (max-width: 1024px) {
  .slider-text {
    font-size: 18px;
    line-height: 1.5;
    padding: 10px 8px;
    max-width: 90%;
  }

  .slider-title {
    font-size: 16px;
  }

  .slider-container {
    max-width: 500px;
  }
}

/* Medium-sized mobile (max-width: 768px) */
@media (max-width: 768px) {
  .slider-text {
    font-size: 16px;
    padding: 8px 5px;
    margin-top: 90px;
  }
 .slider-title {
    font-size: 14px;
  }

  .slider-container {
    max-width: 400px;
  }
}

/* Small mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .slider-text {
    font-size: 14px;
    line-height: 1.4;
    padding: 8px 5px;
  }

  .slider-title {
    font-size: 12px;
  }

  .slider-container {
    max-width: 320px;
  }

  .slider-arrow {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

/* Extra small mobile devices (max-width: 375px) */
@media (max-width: 375px) {
  .slider-text {
    font-size: 12px;
  }

  .slider-title {
    font-size: 10px;
  }

  .slider-container {
    max-width: 280px;
  }

  .slider-arrow {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
}

/* Specific for width of 344px */
@media (max-width: 344px) {
  .slider-container {
    max-width: 260px;
  }
}
