/* Shows the dropdown content when the parent `.dropdown` is hovered over or open */
.navbar-nav > .dropdown:hover > .dropdown-content,
.navbar-nav > .dropdown.open > .dropdown-content {
  display: block;
}
/* Move the navbar menu upwards */
.navbar-nav {
  margin-top: -25px; /* Adjust this value as needed */
}


/* Initially hides the dropdown content, positions it absolutely, and styles it */
.dropdown-content {
  display: none; /* Hidden by default */
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Adds shadow for dropdown */
  min-width: 270px;
  z-index: 1; /* Keeps dropdown above other elements */
}

/* Styles for each list item inside the dropdown */
.dropdown-content li {
  padding: 10px 17px; /* Adds padding to dropdown items */
}

/* Styles for the links inside dropdown items */
.dropdown-content li a {
  color: black;
  font-size: 16px;
  font-weight: bold;
  text-decoration: dotted; /* Adds dotted underline to text */
  display: block;
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

/* Hover effect for dropdown links - changes background and text color */
.dropdown-content li a:hover {
  background-color: #f1f1f1;
  color: darkblue;
}

/* Styles for the dropdown button (non-collapsible version) */
.dropbtn {
  color: darkblue;
  font-size: 18px;
  font-weight: bold;
}

/* Hover and focus styles for navbar links */
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
  background-color: transparent; /* No background on hover */
  color: darkblue; /* Changes link color */
}

/* Styles for links on the right side of the navbar (Help section) */
.navbar-right a {
  color: darkblue;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer; /* Changes cursor to pointer */
  display: flex;
  justify-content: flex-end;
}

/* Logo styles - adjusts image size */
.logo-img {
  height: 50px; /* Default height for logo on desktop */
  width: auto;
}

/* General text styles for items on the right side of the navbar */
.navbar-right p {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  /* Changes the color of the navbar toggle button */
  .navbar-toggle {
    background-color: darkblue;
    border-color: darkblue;
  }

  /* Changes the color of the lines (icon-bar) inside the toggle button */
  .navbar-toggle .icon-bar {
    background-color: white;
  }

  /* Centers and adjusts the right navbar content on mobile */
  .navbar-right {
    position: static;
    float: none; /* Prevents floating */
    text-align: center;
    margin-top: auto;
    
  }

  /* Adjusts the text inside the right navbar section */
  .navbar-right p {
    font-size: 14px;
    padding: 10px;
    margin: 0;
  }

  /* Adjusts dropdown content to be inline on mobile view */
  .dropdown-content {
    position: static;
    box-shadow: none;
    min-width: 100%; /* Full width of screen */
  }

  /* Makes the dropdown links smaller on mobile view */
  .dropdown-content li a {
    font-size: 14px;
  }
}
/* Mobile-specific styles for smaller screens (max-width: 768px) */
@media (max-width: 768px) {
  /* Changes the color of the navbar toggle button */
  .navbar-toggle {
    background-color: darkblue;
    border-color: darkblue;
  }

  /* Changes the color of the lines (icon-bar) inside the toggle button */
  .navbar-toggle .icon-bar {
    background-color: white;
  }

  /* Centers and adjusts the right navbar content on mobile */
  .navbar-right {
    position: static;
    float: none; /* Prevents floating */
    text-align: center;
    margin-top: auto;
    
  }

  /* Adjusts the text inside the right navbar section */
  .navbar-right p {
    font-size: 12px;
    padding: 7px;
    margin: 0;
  }

  /* Adjusts dropdown content to be inline on mobile view */
  .dropdown-content {
    position: static;
    box-shadow: none;
    min-width: 100%; /* Full width of screen */
  }

  /* Makes the dropdown links smaller on mobile view */
  .dropdown-content li a {
    font-size: 10px;
  }
}

/* Additional media queries for small screen sizes */
@media (max-width: 430px) {
  /* Shrinks the logo for smaller screens */
  .logo-img {
    height: 30px;
  }

  /* Adjusts the size of text inside the navbar-right for smaller screens */
  .navbar-right p {
    font-size: 10px;
  }
}
/* Custom breakpoints for popular smaller devices (iPhone 6, SE, etc.) */
@media (max-width: 375px) {
  .logo-img {
    height: 30px;
  }
  
  /* Ensure the navbar-right (Help section) moves above the menu */
  .navbar-right {
    display: flex;
    justify-content: flex-end;
    order: -1; /* Moves Help section above the menu */
    width: 100%;
    padding: 5px;
    margin-top: -10px; /* Adjust if necessary */
    align-items: center;
  }
  
  .navbar-right p {
    font-size: 10px;
  }

  /* Make the toggle menu stack vertically below Help section */
  .navbar-nav {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    order: 2; /* Keeps the navbar menu below the Help section */
    width: 100%; /* Full width */
    align-items: flex-start; /* Align menu items to the left */
  }

  /* Make the individual links in the menu take full width */
  .navbar-nav li {
    width: 100%;
  }

  .navbar-nav li a {
    width: 100%; /* Full width for links */
    padding: 10px; /* Add padding to the links */
  }
}

@media (max-width: 344px) {
  .logo-img {
    height: 26px;
  }

  .navbar-right p {
    font-size: 8px;
  }

  /* Keep the toggle menu vertical on smaller screens */
  .navbar-nav {
    display: flex;
    flex-direction: column; /* Stack menu items vertically */
    width: 100%;
  }
}
