/* General styling */
#contact {
  padding: 30px 0;
}

.container {
  max-width: 100%;
  padding: 0 15px;
}

/* Large Devices (Desktops, 1200px and up) */
@media (min-width: 1200px) {
  .col-md-8 {
    width: 66.66667%;
  }
  .col-md-3 {
    width: 25%;
  }
}

/* Tablets (between 768px and 991px) */
@media (max-width: 991px) {
  .col-md-8, .col-md-3, .col-md-12 {
    width: 100%;
    padding: 0 15px;
  }
  .form-group {
    width: 100%;
  }
  textarea, select, input[type="text"], input[type="email"] {
    width: 100% !important;
    margin-left: 0 !important;
  }

  iframe {
    width: 100% !important;
    height: 300px;
  }

  #success button {
    width: 100%;
  }
}

/* Mobile Devices (up to 767px) */
@media (max-width: 767px) {
  .col-md-8, .col-md-3, .col-md-12 {
    width: 100%;
    padding: 0 15px;
  }

  .form-group {
    width: 100%;
  }

  textarea, select, input[type="text"], input[type="email"] {
    width: 100% !important;
    margin-left: 0 !important;
  }

  /* Adjust font sizes */
  h2, .contact-item h3, .contact-item p, .section-title h2 {
    font-size: 1.5em;
  }

  .social ul {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
  }

  iframe {
    width: 100% !important;
    height: 250px;
  }

  /* Adjust margins for elements */
  .social ul li {
    margin-right: 10px;
  }

  #success button {
    width: 100%;
    margin: 10px 0;
  }
}

/* Small Mobile Devices (up to 575px) */
@media (max-width: 575px) {
  .col-md-8, .col-md-3, .col-md-12 {
    padding: 0 10px;
  }

  textarea, select, input[type="text"], input[type="email"] {
    font-size: 14px;
  }

  /* Adjust button styles */
  #success button {
    font-size: 16px;
    padding: 10px;
  }

  .contact-info {
    padding: 15px 0;
  }
}
