/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  /* Product Container */
  .product-container {
    max-width: 1200px;
    margin: 90px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .trademark {
    font-size: 0.7em; /* Make it smaller than the normal text */
    vertical-align:super; /* Aligns it as superscript */
  }
  /* Product Title */
  .product-title {
    font-size: 3.1rem; /* Adjusted to match other text sizes */
    color: #004d40;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  /* Product Image */

  
  /* Product Description */
  .product-description {
    font-size: 2.1rem; 
    line-height: 1.6;
    margin: 20px 0;
  }
  
  /* Product Sub Title */
  .product-sub-title {
    font-size: 2.1rem;
    color: #00796b;
    margin: 20px 0;
    text-align: center;
  }
  
  /* Product Button */
  .product-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 2.1rem;
    color: #fff;
    background-color: #00796b;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;
    margin: 20px auto;
    display: block;
  }
  
  .product-button:hover {
    background-color: #004d40;
  }
  
  /* Product Video Container */
  .product-video-container {
    text-align: center;
    margin: 40px 0;
  }
  
  /* Product Features styling */
  .product-features {
    background-color: #e0f2f1;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
  }
  
  .product-features h2 {
    font-size: 2.1rem;
    color: #004d40;
    border-bottom: 2px solid #004d40;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .product-features ul {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 2.1rem;
  }
  
  /* Scope of Supply Includes styling */
  .scope-of-supply {
    background-color: #e0f2f1;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
  }
  
  .scope-of-supply h2 {
    font-size: 2.1rem;
    color: #004d40;
    border-bottom: 2px solid #004d40;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .scope-of-supply ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  /* Training Info styling */
  .training-info {
    background-color: #f1f8e9;
    padding: 15px;
    border-radius: 8px;
    font-size: 1.7rem;
    line-height: 1.6;
    margin-top: 20px;
  }
  
  /* Footer styling */
  #footer {
    margin-top: 50px;
    padding: 20px;
    background-color: #f5f7f6;
    color: #fff;
    text-align: center;
  }
  
  .footer-line {
    border-top: 1px solid #fff;
    margin-bottom: 10px;
  }
  
  .container.text-center {
    text-align: center;
  }
  
  .container.text-center h4 {
    margin: 0;
  }
  
  .container.text-center p {
    margin: 0;
    color: #fff;
  }
  
  /* Responsive Design for Tablet */
  @media (max-width: 992px) {
    .product-container {
      margin: 50px auto;
      padding: 10px;
    }
  
    .product-title {
      font-size: 2.5rem;
    }
  
    .product-sub-title,
    .product-features h2,
    .scope-of-supply h2 {
      font-size: 1.8rem;
    }
  
    .product-button {
      font-size: 1.8rem;
      padding: 10px 20px;
    }
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .product-container {
      margin: 20px;
      padding: 10px;
    }
  
    .product-title {
      font-size: 2rem;
    }
  
    .product-description, 
    .product-sub-title,
    .product-features h2,
    .scope-of-supply h2 {
      font-size: 1.6rem;
    }
  
    .product-button {
      font-size: 1.6rem;
      padding: 8px 16px;
    }
  
    .product-video-container iframe {
      width: 100%;
      height: auto;
    }
  }
  