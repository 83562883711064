/* Container for Testimonials */
#testimonials {
  margin-bottom: 40px; /* Creates space between testimonials and the section below */
}

#testimonials .testimonial-item {
  /* margin: 20px; */
  background-color: #f9f9f9;
  height: 200px; /* Reduce the height only */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  margin-bottom: 20px; /* Space below each testimonial item */
}

/* General Styles */
.text-center {
  text-align: center;
}

.section-title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
  text-align: center; /* This ensures the section title is centered */
}

/* Slider Controls */
#testimonials .slider-control {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

#testimonials .prev {
  left: -25px;
}

#testimonials .next {
  right: -25px;
}

#testimonials .slider-control:focus {
  outline: none;
}

/* Testimonial List */
#testimonials .testimonial-slider {
  position: relative;
}

#testimonials .testimonial-list {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}

#testimonials .testimonial-item {
  flex: 1 1 22%;
  margin: 0 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

/* Testimonial Logo */
#testimonials .testimonial-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 15px;
}

/* Testimonial Name */
#testimonials .testimonial-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
}
/* General Styles */
.testimonial-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

/* Mobile and Tablet View (max-width: 1024px) */
@media (max-width: 1024px) {
  /* Testimonials Section */
  #testimonials {
    padding: 0 10px;
    position: relative; /* For arrow positioning */
  }

  /* Testimonial List */
  #testimonials .testimonial-list {
    display: flex; /* Horizontal layout */
    overflow: hidden; /* Hide scrollbar */
    scroll-behavior: smooth; /* Smooth scrolling */
  }

  /* Testimonial Item */
  #testimonials .testimonial-item {
    flex: 0 0 30%; /* Each testimonial takes up 30% of the screen width */
    margin-right: 10px; /* Space between testimonials */
    padding: 20px;
    text-align: center;
  }

  /* Adjustments for Right-to-Left Scrolling */
  #testimonials .testimonial-list {
    flex-direction: row-reverse; /* Right-to-left flow */
  }

  /* Testimonial Logo */
  #testimonials .testimonial-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  /* Testimonial Name */
  #testimonials .testimonial-name {
    font-size: 16px;
  }

  /* Section Title */
  .section-title {
    font-size: 24px;
    margin-bottom: 15px;
  }

  /* Slider Controls (Next/Prev Arrows) */
  #testimonials .slider-control {
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  /* Left arrow */
  #testimonials .prev {
    left: 0;
  }

  /* Right arrow */
  #testimonials .next {
    right: 0;
  }

  /* Mobile-specific adjustments */
  @media (max-width: 767px) {
    #testimonials .testimonial-item {
      flex: 0 0 45%; /* On very small screens, each testimonial takes 45% of the width */
      margin-right: 5px; /* Reduced margin for smaller screens */
    }

    .section-title {
      font-size: 22px; /* Smaller title on mobile */
    }
  }
}
