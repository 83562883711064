/* Global Styles */
* {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  color: #333;
}


/* Product Container */
.product-container {
  max-width: 2000px; /* Increased max-width for larger card */
  margin: 70px auto; /* Center container */
  background-color: #fff;
  box-shadow: 0 6px 10px rgba(189, 160, 160, 0.1);
  border-radius: 12px;
  padding: 40px; /* Increased padding for better spacing */
}

/* Product Title */
.product-title {
  font-size: 3.1rem;
  color: #004d40;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

/* Image Slider */
.image-slider {
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center images */
  margin: 20px 0; /* Spacing around slider */
  gap: 15px; /* Space between images */
  flex-wrap: flex;
}

.product-image {
  border: 2px solid transparent; /* Added border   #00796b */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px transparent; /* Shadow for depth  rgba(0, 0, 0, 0.1)*/
  margin: 0 5px; /* Space between images */
  height: 700px; /* Set a fixed height */
  object-fit: contain; /* Maintain aspect ratio */
}

/* Adjustments for individual images if needed */
/* Responsive styles for individual images */
.image1 {
  width: 30%; /* Responsive width */
}

.image2 {
  width: 35%; /* Responsive width */
}

.image3 {
  width: 40%; /* Responsive width */
}

.image4 {
  width: 45%; /* Responsive width */
}
/* Product Description */
.product-description {
  font-size: 2.1rem;
  line-height: 1.5; /* Improved line height */
}

/* Product Sub Title */
.product-sub-title {
  font-size: 2.1rem;
  color: #00796b;
  margin: 20px 0;
  text-align: center;
}

/* Product Button */
.product-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 2.1rem;
  color: #fff;
  background-color: #00796b;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-align: center;
  margin: 20px auto;
}

.product-button:hover {
  background-color: #004d40;
}

/* Product Features styling */
.product-features,
.scope-of-supply {
  background-color: #e0f2f1;
  padding: 20px;
  border-radius: 8px;
}

.product-features h2,
.scope-of-supply h2 {
  font-size: 2.2rem;
  color: #004d40;
  border-bottom: 2px solid #004d40;
  margin-bottom: 15px;
}

.product-features ul,
.scope-of-supply ul {
  list-style-type: disc;
  padding-left: 20px; /* Added padding for list */
}

/* Training Info styling */
.training-info {
  background-color: #f1f8e9;
  padding: 15px;
  border-radius: 8px;
  font-size: 1.7rem;
  line-height: 1.2;
  margin-top: 20px;
}

/* Footer styling */
#footer {
  margin-top: 50px;
  padding: 20px;
  background-color: #00796b; /* Change to match theme */
  color: #fff;
  text-align: center;
}

.footer-line {
  border-top: 1px solid #fff;
  margin-bottom: 10px;
}

.container.text-center {
  text-align: center;
}

.container.text-center h4 {
  margin: 0;
}

.container.text-center p {
  margin: 0;
  color: #fff;
}



/* Responsive Design */
/* Media Query for 1024px */
@media (max-width: 1024px) {
  .image-slider {
    flex-wrap: wrap; /* Enable wrapping for images */
  }

  .product-image {
    width: 50%; /* Two images per row at this screen size */
    max-height: 400px; /* Adjust max height */
    margin-bottom: 20px; /* Space between images */
  }

  .product-title {
    font-size: 2.5rem; /* Adjust title size */
  }

  .product-sub-title,
  .product-description {
    font-size: 1.8rem; /* Adjust text size */
  }

  .product-button {
    font-size: 1.8rem; /* Adjust button font size */
    padding: 12px 24px; /* Adjust button padding */
  }

  /* Product Features and Supply Section */
  .product-features h2,
  .scope-of-supply h2 {
    font-size: 2rem; /* Adjust section title size */
  }

  .product-description ul {
    padding-left: 15px; /* Adjust padding for lists */
  }

  /* Reduce padding for the product container */
  .product-container {
    padding: 30px; /* Reduce padding for smaller screens */
  }

  /* Training Info Styling */
  .training-info {
    font-size: 1.6rem; /* Adjust font size for smaller screens */
  }

  /* Footer */
  #footer {
    padding: 15px; /* Adjust footer padding */
  }

  .footer-line {
    margin-bottom: 8px; /* Adjust margin for smaller footer */
  }
}

@media (max-width: 768px) {
  .product-container {
    padding: 15px;
    margin: 0 auto; /* Reapply auto margin for smaller screens */
  }

  .product-container,
  .product-title,
  .product-sub-title,
  .product-description {
    text-align: left; /* Ensure text is centered on smaller screens */
  }
  .image-slider {
    flex-wrap: wrap; /* Added flex-wrap for mobile */
  }
  .product-image {
    width: 100%; /* Full width for images on mobile */
    max-height: 300px; /* Adjust max height */
    margin-bottom: 10px; /* Space between images */
  }

  .product-title {
    font-size: 2rem; /* Smaller title for smaller screens */
  }

  .product-sub-title,
  .product-description {
    font-size: 1.5rem; /* Smaller text */
  }

  .product-button {
    font-size: 1.5rem; /* Smaller button font size */
    padding: 10px 20px; /* Smaller button padding */
  }
}

/* Media Query for 720px */
@media (max-width: 720px) {
  .image-slider {
    flex-wrap: wrap; /* Added flex-wrap for mobile */
  }
  .product-image {
    width: 45%; /* Responsive width for two images */
    max-height: 300px; /* Maintain height */
  }

  .product-title {
    font-size: 1.9rem; /* Smaller title for smaller screens */
  }

  .product-sub-title {
    font-size: 1.5rem; /* Smaller subtitle */
  }

  .product-description {
    font-size: 1.6rem; /* Smaller description text */
  }

  .product-button {
    font-size: 0.9rem; /* Smaller button font size */
  }
}

/* Media Query for 480px */
@media (max-width: 480px) {
  .image-slider {
    flex-wrap: wrap; /* Added flex-wrap for mobile */
  }
  .product-image {
    width: 100%; /* Full width for images on very small screens */
    max-height: 200px; /* Set a max-height for better visibility */
  }

  .product-title {
    font-size: 1.5rem; /* Further reduced title size for very small screens */
  }

  .product-sub-title,
  .product-features h2,
  .scope-of-supply h2 {
    font-size: 1.2rem; /* Smaller sub-title and section titles */
  }

  .product-description {
    font-size: 1.5rem; /* Smaller description text */
  }

  .product-button {
    font-size: 1rem; /* Smaller button font size */
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }

  /* Reduce list item padding */
  .product-description ul {
    padding-left: 10px; /* Reduce padding for lists */
  }
}

/* Media Query for 400px */
@media (max-width: 400px) {
  .image-slider {
    flex-wrap: wrap; /* Added flex-wrap for mobile */
  }
  .product-image {
    width: 100%; /* Full width for images */
    max-height: 250px; /* Set a max-height for better visibility */
  }

  .product-title {
    font-size: 1.8rem; /* Slightly smaller title for very small screens */
  }

  .product-sub-title,
  .product-features h2,
  .scope-of-supply h2 {
    font-size: 1.3rem; /* Smaller sub-title and section titles */
  }

  .product-description {
    font-size: 1.5rem; /* Smaller description text */
  }

  .product-button {
    font-size: 0.9rem; /* Smaller button font size */
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }
}

/* Media Query for 375px */
@media (max-width: 375px) {
  .image-slider {
    flex-wrap: wrap; /* Added flex-wrap for mobile */
  }
  .product-image {
    width: 100%; /* Full width for images */
    max-height: 220px; /* Set a max-height for better visibility */
  }

  .product-title {
    font-size: 1.7rem; /* Further reduced title size */
  }

  .product-sub-title,
  .product-features h2,
  .scope-of-supply h2 {
    font-size: 1.2rem; /* Even smaller sub-title and section titles */
  }

  .product-description {
    font-size: 1.4rem; /* Smaller description text */
  }

  .product-button {
    font-size: 0.85rem; /* Smaller button font size */
    padding: 7px 14px; /* Adjust padding for smaller buttons */
  }
}

/* Media Query for 275px */
@media (max-width: 275px) {
  .image-slider {
    flex-wrap: wrap; /* Added flex-wrap for mobile */
  }
  .product-image {
    width: 100%; /* Full width for images */
    max-height: 180px; /* Set a max-height for better visibility */
  }

  .product-title {
    font-size: 1.5rem; /* Further reduced title size for very small screens */
  }

  .product-sub-title,
  .product-features h2,
  .scope-of-supply h2 {
    font-size: 1.1rem; /* Smaller sub-title and section titles */
  }

  .product-description {
    font-size: 1.2rem; /* Smaller description text */
  }

  .product-button {
    font-size: 0.75rem; /* Smaller button font size */
    padding: 6px 12px; /* Adjust padding for smaller buttons */
  }

  /* Additional adjustments for list items */
  .product-features ul,
  .scope-of-supply ul {
    padding-left: 10px; /* Reduce padding for lists */
  }
}